import axios from "axios";
import Vue from "vue";
import store from "../../store/index";
import router from "../../router/index";
import $log from "@/utils/service/log";
import Cookies from "js-cookie";

let Axios = axios.create({
  baseURL: process.env.VUE_APP_API,
  timeout: 100000,
  headers: {
    common: {
      Accept: "application/json;charset=utf-8",
      "Content-Type": "text/plain",
      "Strict-Transport-Security": "max-age=7776000; includeSubDomains",
      "X-XSS-Protection": "1; mode=block",
      "X-Content-Type-Options": "nosniff",
    },
  },
});

function getToken() {
  return store.state.auth.token;
}

Axios.interceptors.request.use(
  (config) => {
    let token = getToken();

    if (
      !token &&
      ![
        /\/auth\/email/i,
        /\/demo\/?/i,
        /\/auth/i,
        /\/scan/i,
        /\/templates?/i,
        /\/business\/reg/i,
      ].find((url) => config.url.match(url))
    ) {
      // store.dispatch("logout");
      return {
        ...config,
        // eslint-disable-next-line no-undef
        cancelToken: new CancelToken((cancel) =>
          cancel("Client Token is missing")
        ),
      };
    }

    config.headers.common["Authorization"] = `Bearer ${token}`;

    try {
      config.headers.common["Analytics"] = Cookies.get("_ga");
    } catch (e) {
      $log.notify(e);
    }

    try {
      const localeFromUrl = window.location.pathname.match(/\/([a-z]{2})\//);

      config.headers.common["Locale"] =
        router?.currentRoute?.params?.locale ||
        (localeFromUrl?.length > 1 ? localeFromUrl[1] : null) ||
        "en";
    } catch (e) {
      $log.notify(e);
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    authToken(response);

    return response?.data;
  },
  function (error) {
    let showError = true;
    authToken(error?.response);

    switch (error?.response?.status) {
      case 401:
        showError = false;
        store.dispatch("logout");
        break;
      case 453:
        showError = false;
        store.commit("changeTermsAgree", true);
        break;
    }

    return Promise.reject({
      status: error?.response?.status,
      showError,
      error: error?.response?.data?.error,
      response: error?.response,
      url: error?.response?.config?.url,
    });
  }
);

function authToken(response) {
  const newToken = response?.headers?.authorization?.replace(/^Bearer\s/i, "");

  if (newToken) {
    alert("set new token", newToken);
    store.commit("SET_TOKEN", newToken);
  }
}

Vue.prototype.$axios = Axios;

export default Axios;
