import { setAmplitudeEvent } from "./utils/amplitude";
import theLog from "./utils/service/log";

export const eventLog = (event, options, amplitudeEvent = "") => {
  // console.log(event, options, amplitudeEvent);

  setAmplitudeEvent("business." + (amplitudeEvent || event), options);

  try {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: event,
      ...options,
    });
  } catch (e) {
    theLog.notify(e);
  }
};
