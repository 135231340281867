export default [
  {
    path: "settings",
    meta: {
      title: "Personal settings",
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsWrapper" */ "../../views/Wrapper.vue"
      ),
    children: [
      {
        path: "",
        name: "settings",
        meta: {
          title: "Personal settings",
        },
        component: () =>
          import(/* webpackChunkName: "Settings" */ "../../views/Settings.vue"),
      },
      {
        path: "billing",
        name: "billing",
        meta: {
          title: false,
        },
        component: () =>
          import(/* webpackChunkName: "Billing" */ "../../views/Billing.vue"),
      },
    ],
  },
];
