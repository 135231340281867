import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import authModule from "./authModule";
import questionnaireModule from "./questionnaireModule";
import clientModule from "./clientModule";
import websiteModule from "./websiteModule";
import businessModule from "./businessModule";
import translationsModule from "./translationsModule";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  key: process.env.VUE_APP_STORAGE_NAME,
  modules: ["auth", "user", "business" /*, "translation"*/],
  restoreState: (key, storage) => {
    const value = storage.getItem(key);
    return typeof value === "string" ? JSON.parse(value || "{}") : value || {};
  },
  // saveState: (key, state, storage) => {
  //     try {
  //         storage.setItem(key, JSON.stringify(state));
  //     } catch (e) {
  //         storage.clear()
  //         state.setItem(key, JSON.stringify({auth: state.auth, client: state.client}));
  //     }
  // }
});

let store = new Vuex.Store({
  plugins: [vuexLocal.plugin],
  strict: process.env.NODE_ENV !== "production",
  state: {
    termsAgree: false,
  },
  mutations: {
    changeTermsAgree(state, data = false) {
      state.termsAgree = data;
    },
  },
  actions: {},
  modules: {
    auth: authModule,
    questionnaire: questionnaireModule,
    client: clientModule,
    website: websiteModule,
    business: businessModule,
    translation: translationsModule,
  },
});

export default store;
