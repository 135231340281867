import http from "@/utils/service/http";
import { setAmplitudeUser } from "../utils/amplitude";

export default {
  state: {
    email: null,
    emailVerified: null,
    id: null,
    uuid: null,
    card_brand: null,
    card_last_four: null,
    subscribed_to_newsletter: false,
    avatar: null,
    subscriptions: [],
    nonActiveSubscriptions: [],
    days: 0,
    documents: 0,
    projects: 0,
    contracts: 0,
    tosagree: true,
    locale: "en",
    country: "US",
    business: true,
  },
  getters: {
    showLanguageSelect(state) {
      return state.country === "LT" || state.locale === "lt";
    },
    getCurrentSubscription: (state) => () => {
      return [...state.subscriptions].pop();
    },
  },
  mutations: {
    SET_CLIENT_DATA(state, data = {}) {
      if (data.email) state.email = data.email;
      if (data.id) state.id = data.id;
      if (data.uuid) state.uuid = data.uuid;
      if (data.subscriptions) state.subscriptions = data.subscriptions;
      state.emailVerified = data.email_verified;
      state.nonActiveSubscriptions = data.nonActiveSubscriptions;
      state.subscribed_to_newsletter = data.subscribed_to_newsletter;
      state.card_brand = data.pm_type;
      state.card_last_four = data.pm_last_four;
      state.avatar = data.avatar;
      state.days = data.days;
      state.documents = data.documents;
      state.projects = data.projects;
      state.contracts = data.contracts;
      state.tosagree = data.tosagree;
      state.chat_hash = data.chat_hash;
      state.locale = data.locale;
      state.country = data.country;
      state.business = data.business;
    },
    SET_SUBSCRIPTIONS(state, subscriptions) {
      state.subscriptions = subscriptions;
    },
    UPDATE_SUBSCRIBED_TO_NEWSLETTER(state, data = false) {
      state.subscribed_to_newsletter = data;
    },
    UPDATE_SUBSCRIPTION(state, newSubscription) {
      state.subscriptions = state.subscriptions.filter(
        ({ stripe_id }) => stripe_id !== newSubscription.stripe_id
      );

      // Object.entries(newSubscription).forEach(([key, value]) => {
      //     subscription[key] = value;
      // })

      state.subscriptions.push(newSubscription);
    },
  },
  actions: {
    async clientDataIndex(context) {
      if (!context.rootState.auth.token) return;

      const data = await http.get("/api/v1/me");

      context.commit("SET_CLIENT_DATA", data);

      setChatUser(data);
      setAmplitudeUser(data);

      return data;
    },
    async clientSubscriptions(context) {
      if (!context.rootState.auth.token) return;

      const subscriptions = await http.get("/api/v1/payments/subscriptions");

      context.commit("SET_SUBSCRIPTIONS", subscriptions);

      return subscriptions;
    },
  },
};

const setChatUser = (data) => {
  const setChat = setInterval(() => {
    try {
      if (typeof Tawk_API !== "undefined") {
        // eslint-disable-next-line no-undef
        Tawk_API.setAttributes({
          email: data.email,
          hash: data.chat_hash,
        });

        clearInterval(setChat);
      }
    } catch {
      // do nothing
    }
  }, 2000);
};
