/* eslint-disable */
export const setAmplitudeUser = (data) => {
  try {
    amplitude.getInstance().setUserId(data.id);
    amplitude.getInstance().setUserProperties({
      country: data.country,
      business: data.business,
      projects_count: data.projects,
      email_verified: data.email_verified,
      subscriptions_count: data.subscriptions?.length || 0,
    });
  } catch { /* do nothing */}
};

export const setAmplitudePageView = (routeTo, routeFrom = null) => {
  try {
    amplitude.getInstance().logEvent("pageview", {
      url: routeTo.path,
      full_url: routeTo.fullPath,
      from_url: routeFrom.path,
      from_full_url: routeFrom.fullPath,
    });
  } catch { /* do nothing */}
};

export const setAmplitudeEvent = (event, params = {}) => {
  try {
    amplitude.getInstance().logEvent(event, params);
  } catch { /* do nothing */}
};

export const setAmplitudeRevenue = (productId, price, quantity = 1) => {
  try {
    const revenue = new amplitude.Revenue()
      .setProductId(productId)
      .setPrice(price)
      .setQuantity(quantity);
    amplitude.getInstance().logRevenueV2(revenue);
  } catch { /* do nothing */}
};

export const init = (amplitudeApiKey) => {
  if(amplitudeApiKey) {
    const setAmplitudeClick = (event) => {
      try {
        const target = event.target;

        if (["a", "button", "input"].indexOf(target.tagName.toLowerCase()) >= 0
            || ["a", "button", "input"].indexOf(target.parentNode.tagName.toLowerCase()) >= 0) {
          amplitude.getInstance().logEvent("business.click", {
            id: target.id,
            class: target.className,
            text: target.innerHTML,
            tag: target.tagName,
            url: window.location.pathname,
          });
        }
      } catch {
      }
    }

    addEventListener("click", setAmplitudeClick);

    amplitude.getInstance().init(amplitudeApiKey);

    try {
      const identify = new amplitude.Identify();
      amplitude.getInstance().identify(identify);

      // amplitude.getInstance().logEvent("pageview", {
      //   url: window.location.pathname,
      //   full_url: window.location.url,
      // });
    } catch {
    }
  }
}
