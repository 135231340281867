import Vue from "vue";
import Vuetify from "vuetify/lib";
import { translation } from "./utils/service/Translations/translations";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    default: "light",
    themes: {
      light: {
        primary: "#485AFF",
        secondary: "#FFC800",
        success: "#00B6A4",
        error: "#E15454",
        warning: "#FF9C00",
        info: "#17A6FF",
        accent: "#8d9db5",
        secondaryDark: "#404040",
        light: "#ffffff",
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 2500,
    },
  },
  lang: {
    t: (key, ...params) => translation.t(key, params),
  },
});
