import Vue from "vue";
import VueI18n from "vue-i18n";
import globalVariables from "../../../utils/globalVariables";

Vue.use(VueI18n);

// Import Locales
import en from "./locales/en.json";
import { en as VuetifyEN, lt as VuetifyLT } from "vuetify/lib/locale";
import lt from "./locales/lt.json";

let messages = {
  en: {
    ...en,
    $vuetify: VuetifyEN,
  },
  lt: {
    ...lt,
    $vuetify: VuetifyLT,
  },
};

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
  silentTranslationWarn: true,
  missing: (locale, key) => {
    // if(!window.trans) window.trans = []
    //
    // window.trans = window.trans.filter(k => k !== key);
    // window.trans.push(key);

    // trans(key);

    // store.commit("ADD_TRANSLATION", key);
    // if(locale && locale !== "en" && locale !== "in" && locale !== "us") {
    // http.post('/api/v1/translation', {
    //     "message": key,
    //     "locale": locale
    // });
    // }

    messages[locale] = {
      ...(messages[locale] || {}),
      [key]: key,
    };
  },
  pluralizationRules: {
    lt: function (choice) {
      if (choice === 0) {
        return 0;
      }

      if (choice === 1 || choice % 10 === 1) return 1;

      return 2;
    },
  },
});

export const translation = {
  instance: () => i18n,
  t: (key, params = {}) => {
    let translated = i18n.t(key, { ...globalVariables, ...params });
    const matches = translated.match(/{(.+)}/gim);

    if (matches) {
      matches.forEach((param) => {
        translated = translated?.replace(
          param,
          params[param?.replace("{", "")?.replace("}", "")] || ""
        );
      });
    }

    return translated;
  },
  tl: (key, locale, params = {}) => {
    let translated = i18n.t(key, locale, { ...globalVariables, ...params });
    const matches = translated.match(/{(.+)}/gim);

    if (matches) {
      matches.forEach((param) => {
        translated = translated?.replace(
          param,
          params[param?.replace("{", "")?.replace("}", "")] || ""
        );
      });
    }

    return translated;
  },
  tc: (...args) => i18n.tc(...args),
  locale: () => i18n.locale,
  setLocale: (newLocale = "en") => (i18n.locale = newLocale),
};

Vue.prototype.$translation = translation;

export default i18n;
