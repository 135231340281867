import multiguard from "vue-router-multiguard";
import tokenMiddleware from "../../middlewares/tokenMiddleware";
import http from "../../utils/service/http";
import questionnaireRoutes from "./questionnaire/index";
import cookieConsentRoutes from "./cookieConsentRoutes";
import profileRoutes from "./profileRoutes";

export default [
  {
    path: "",
    name: "home",
    redirect: (to) => ({
      ...to,
      name: "documents",
    }),
  },
  {
    path: "documents",
    name: "documents",
    component: () =>
      import(/* webpackChunkName: "Home" */ "../../views/Home.vue"),
    beforeEnter: multiguard([
      tokenMiddleware,
      async (to, from, next) => {
        const business = await http.get("/api/v1/business");

        if (business === false) {
          next({
            ...to,
            name: "welcome",
          });
        }

        next();
      },
    ]),
  },
  ...questionnaireRoutes,
  {
    path: "documents/:slug",
    name: "project_document_index",
    meta: {
      title: false,
      // sideCollapse: true
    },
    component: () =>
      import(
        /* webpackChunkName: "Document" */ "../../views/Project/Documents/Document.vue"
      ),
  },
  ...profileRoutes,
  ...cookieConsentRoutes,
];
