import Vue from "vue";
import VueRouter from "vue-router";
import multiguard from "vue-router-multiguard";
import tokenMiddleware from "../middlewares/tokenMiddleware";
import spaRoutes from "./spa/index";
import questionnaire from "./questionnaire";
import onboarding from "./onboarding";
import authRoutes from "./authRoutes";
import { setAmplitudePageView } from "../utils/amplitude";

Vue.use(VueRouter);

const routes = [
  {
    path: "/emp",
    component: () => import("../views/Empty.vue"),
  },
  {
    path: "/:locale([a-z]{2})?",
    component: () => import("../views/Locale.vue"),
    children: [
      ...questionnaire,
      {
        path: "",
        component: () => import("../Wrapper.vue"),
        beforeEnter: multiguard([tokenMiddleware]),
        children: spaRoutes,
      },
      ...authRoutes,
      ...onboarding,
      {
        path: "*",
        name: "not_found",
        meta: {
          title: "Page not found",
          hideTitle: true,
        },
        component: () => import("../views/Lost.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  setAmplitudePageView(to, from);
  // if (from.params.locale && !to.params.locale) {
  //     next({
  //         name: to.name,
  //         params: {
  //             ...to.params,
  //             locale: from.params.locale,
  //         },
  //     });
  // }
  if (to.params.locale === "en") {
    return next({
      name: to.name,
      params: {
        ...to.params,
        locale: undefined,
      },
    });
  }

  next();
});

export default router;
