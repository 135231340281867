import $axios from "../utils/service/http";

const state = {
  progress: [],
};

const getters = {
  businessProgressByType: (state) => (stepType) => {
    return !!state.progress.find(({ type }) => type === stepType)?.exists;
  },
};

const mutations = {
  SET_BUSINESS_PROGRESS(state, progress) {
    state.progress = progress;
  },
};

const actions = {
  async fetchBusinessProgress({ commit }) {
    const progress = await $axios.get("/api/v1/business/progress");

    commit("SET_BUSINESS_PROGRESS", progress);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
