<template>
  <v-app id="vueapp">
    <component :is="getLayout">
      <router-view />
    </component>
    <v-dialog
      v-model="agreeShow"
      max-width="540"
      transition="dialog-bottom-transition"
      persistent
    >
      <terms-agree @close="updateTerms" />
    </v-dialog>
  </v-app>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import Cookies from "js-cookie";

export default {
  metaInfo() {
    return {
      titleTemplate: (title) =>
        [title, this.$global.brand].filter((t) => !!t).join(" | "),
      title: this.$translation.t(this.$route.meta?.title, {
        ...this.$route.params,
        ...(this.$global || {}),
      }),
    };
  },
  async created() {
    this.$log.setUser(this.userId);
    await this.getClientData();
  },
  computed: {
    getLayout() {
      try {
        let matchedRoutesLayout = this.$route.matched.map(
          ({ meta }) => meta.layout
        );

        matchedRoutesLayout.push(this.$route?.meta?.layout);

        matchedRoutesLayout = matchedRoutesLayout.filter((layout) => !!layout);

        let selectedLayout = matchedRoutesLayout.pop();

        if (selectedLayout) {
          if (typeof selectedLayout === "string") {
            return () => import(`@/layouts/${selectedLayout}`);
          } else if (typeof selectedLayout === "function") {
            return selectedLayout;
          }
        }
      } catch (e) {
        this.$log.notify(e);
      }

      return () => import("@/layouts/Default");
    },
    ...mapState({
      userId: (state) => state.auth.userId,
      agreeShow: (state) => state.termsAgree,
    }),
  },
  methods: {
    async getClientData() {
      try {
        const client = await this.clientDataIndex();
        this.$log.setUser(client?.id, client?.email);

        this.redirectUserToPreferredLocale(client);

        return client;
      } catch {
        return null;
      }
    },
    redirectUserToPreferredLocale(client) {
      if (
        !Cookies.get("preferred_redirect") &&
        client.preferred_locales &&
        !client.preferred_locales.includes(this.$utils.locale())
      ) {
        this.$router.replace({
          name: this.$route.name,
          params: {
            ...this.$route.params,
            locale: [...client.preferred_locales]?.shift(),
          },
        });
      }

      Cookies.set("preferred_redirect", true);
    },
    updateTerms() {
      this.changeTermsAgree();
      this.getClientData();
    },
    ...mapMutations(["changeTermsAgree"]),
    ...mapActions(["clientDataIndex"]),
  },
  watch: {
    userId() {
      this.getClientData();
    },
  },
};
</script>
