import tokenMiddleware from "../middlewares/tokenMiddleware";
import multiguard from "vue-router-multiguard";
import http from "../utils/service/http";

export default [
  {
    path: "welcome",
    meta: {
      layout: () => import("../layouts/OnboardingLayout"),
    },
    alias: ["sveiki"],
    component: () => import("../views/Onboarding/Onboarding.vue"),
    beforeEnter: multiguard([
      tokenMiddleware,
      async (to, from, next) => {
        const progress = await http.get("/api/v1/business/progress");

        if (progress && progress.find(({ exists }) => exists)) {
          next({
            ...to,
            name: "home",
          });
        }

        next();
      },
    ]),
    children: [
      {
        path: "",
        name: "welcome",
        component: () => import("../views/Onboarding/UserRole.vue"),
      },
      {
        path: "data_usage_purpose",
        meta: {
          title: false,
          layout: () => import("../layouts/Generation"),
          sideMenuComponent: () =>
            import("../components/Questionnaire/Sidebar/ProductSteps"),
        },
        component: () =>
          import(
            /* webpackChunkName: "QuestionnaireDataUsagePurpose" */ "../views/Onboarding/OnboardingQuestionnaireDataUsagePurpose"
          ),
        children: [
          {
            path: "",
            name: "welcome_questionnaire_data_usage_purpose",
            component: () =>
              import(
                /* webpackChunkName: "QuestionnaireDataUsagePurposeOverview" */ "../views/Project/Questionnaire/DataUsagePurposes/DataUsagePurposeOverview.vue"
              ),
          },
          {
            path: "new",
            name: "welcome_questionnaire_data_usage_purpose_new",
            component: () =>
              import(
                /* webpackChunkName: "QuestionnaireDataUsagePurposeNew" */ "../views/Project/Questionnaire/DataUsagePurposes/DataUsagePurpose"
              ),
          },
          {
            path: ":uuid",
            name: "welcome_questionnaire_data_usage_purpose_edit",
            component: () =>
              import(
                /* webpackChunkName: "QuestionnaireDataUsagePurposeEdit" */ "../views/Project/Questionnaire/DataUsagePurposes/DataUsagePurpose"
              ),
          },
        ],
      },
      {
        path: ":type",
        name: "welcome_questionnaire",
        meta: {
          layout: () => import("../layouts/Generation"),
          sideMenuComponent: () =>
            import("../components/Questionnaire/Sidebar/ProductSteps"),
        },
        component: () => import("../views/Onboarding/OnboardingQuestionnaire"),
        beforeEnter(to, from, next) {
          if (to.params.type === "data_usage_purpose") {
            return next({
              name: "welcome_questionnaire_data_usage_purpose",
              params: { locale: to.params.locale },
            });
          }

          next();
        },
      },
    ],
  },
];
