import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import Vue from "vue";

try {
  if (process.env.VUE_APP_LOG_ID) {
    Bugsnag.start({
      apiKey: process.env.VUE_APP_LOG_ID,
      appVersion: process.env.VUE_APP_VERSION,
      plugins: [new BugsnagPluginVue()],
      onUnhandledRejection: function (err) {
        if (err.status === 453) return false;
      },
      onError: function (event) {
        if (event.originalError.status === 453) return false;
      },
    });

    const bugsnagVue = Bugsnag.getPlugin("vue");
    bugsnagVue.installVueErrorHandler(Vue);
  }
} catch {
  // Do nothing
}

const logger = {
  ...Bugsnag,
  notify: (error, ...args) => {
    if (process.env.ENV !== "production") console.error(error, args);
    if (error instanceof Error) {
      Bugsnag.notify(error, args);
    } else {
      Bugsnag.notify(
        new Error(error.error || error.message || "unknown error"),
        (event) => {
          try {
            Object.entries(error).forEach(([key, value]) => {
              event.addMetadata(key, value);
            });
          } catch {
            // Do nothing
          }
        }
      );
    }
  },
};

const dummyLogger = {
  setUser: () => {},
  notify: (error, ...args) => {
    console.error(error, args);
  },
};

const theLog = process.env.ENV !== "production" ? dummyLogger : logger;

Vue.prototype.$log = theLog;

export default theLog;
