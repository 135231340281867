export default {
  state: {
    translations: {},
  },
  mutations: {
    ADD_TRANSLATION(state, data) {
      state.translations = {
        ...state.translations,
        [data]: "",
      };
    },
  },
};
