import Vue from "vue";
import i18n from "@/utils/service/Translations/translations";

const currency = function (price, currency = null, locale = null) {
  if (!currency && !locale) locale = i18n.locale;

  switch (currency || locale) {
    case "lt":
    case "EUR":
      return `€${price}`;
    case "MYR":
      return `RM${price}`;
    case "en":
    case "USD":
    default:
      return `$${price}`;
  }
};

const pricingService = {
  currency,
  format: (price, cur = null) => {
    price = pricingService.precision(price);
    if (price === "NaN") price = "_.__";

    return currency(price, cur);
  },
  precision(price, precision = 2) {
    return parseFloat(price).toFixed(precision);
  },
};

Vue.prototype.$price = pricingService;
