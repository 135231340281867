export default [
  {
    path: "business-data/data_usage_purpose",
    meta: {
      title: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "QuestionnaireDataUsagePurpose" */ "../../../views/Project/Questionnaire/QuestionnaireDataUsagePurpose.vue"
      ),
    children: [
      {
        path: "",
        name: "questionnaire_type_data_usage_purpose",
        meta: {
          title: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "QuestionnaireDataUsagePurposeOverview" */ "../../../views/Project/Questionnaire/DataUsagePurposes/DataUsagePurposeOverview.vue"
          ),
      },
      {
        path: "new",
        name: "questionnaire_type_data_usage_purpose_new",
        meta: {
          title: false,
          layout: () => import("../../../layouts/Generation"),
        },
        component: () =>
          import(
            /* webpackChunkName: "QuestionnaireDataUsagePurposeNew" */ "../../../views/Project/Questionnaire/DataUsagePurposes/DataUsagePurpose"
          ),
      },
      {
        path: ":uuid",
        name: "questionnaire_type_data_usage_purpose_edit",
        meta: {
          title: false,
          layout: () => import("../../../layouts/Generation"),
        },
        component: () =>
          import(
            /* webpackChunkName: "QuestionnaireDataUsagePurposeEdit" */ "../../../views/Project/Questionnaire/DataUsagePurposes/DataUsagePurpose"
          ),
      },
    ],
  },
  {
    path: "business-data/:type",
    name: "questionnaire_type",
    meta: {
      title: false,
      layout: () => import("../../../layouts/Generation"),
    },
    component: () =>
      import(
        /* webpackChunkName: "Questionnaire" */ "../../../views/Project/Questionnaire/Questionnaire.vue"
      ),
    beforeEnter(to, from, next) {
      if (to.params.type === "data_usage_purpose") {
        next({
          name: "questionnaire_type_data_usage_purpose",
          params: { locale: to.params.locale },
        });
        return;
      }

      next();
    },
  },
];
