import store from "@/store";

export default [
  {
    path: "login",
    name: "login",
    meta: {
      layout: "NoHeader",
      title: "Login",
    },
    alias: ["prisijungti"],
    component: () => import("../views/Public/Login.vue"),
    beforeEnter(to, from, next) {
      if (store.state.auth.token) {
        next({
          name: "welcome",
          params: to.params,
        });
      }

      next();
    },
  },

  {
    path: "register",
    name: "register",
    meta: {
      layout: "NoHeader",
      title: "Register",
    },
    alias: ["registruotis"],
    component: () => import("../views/Public/Register.vue"),
    beforeEnter(to, from, next) {
      if (store.state.auth.token) {
        next({
          name: "welcome",
          params: to.params,
        });
      }

      next();
    },
  },
  {
    path: "password-reset/:token",
    name: "password-reset",
    meta: {
      layout: "NoHeader",
      title: "Password Reset",
    },
    alias: ["slaptazodzio-keitimas/:token"],
    component: () => import("../views/Public/PasswordReset.vue"),
  },
  {
    path: "auth",
    name: "auth",
    meta: {
      layout: "NoHeader",
      title: "Authenticate",
    },
    component: () => import("../views/Auth.vue"),
  },
];
