import router from "../router/index";

export default {
  state: {
    token: "",
    userId: "",
  },
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER_ID(state, userId) {
      state.userId = userId;
    },
  },
  actions: {
    logout({ commit }) {
      commit("SET_TOKEN", null);
      commit("SET_USER_ID", null);

      window?.localStorage?.removeItem(process.env.VUE_APP_STORAGE_NAME);

      router
        .push({ name: "login", params: router.currentRoute.params })
        .catch(() => {});
    },
  },
};
