let state = {
  questions: null,
  details: {},
  currentQuestion: 1,
  currentErrors: {},
  currentQuestionStatus: [],
  data: [],
};

let mutations = {
  SET_QUESTIONS(state, data) {
    state.questions = data;
  },
  SET_START_DETAILS(state, data) {
    state.details = data;
  },
  RESET_CURRENT_QUESTION(state, step = 1) {
    state.currentQuestion = step;
    state.currentQuestionStatus = [];
    state.currentErrors = {};
  },
  SET_CURRENT_QUESTION(state, step) {
    state.currentQuestion = step;
  },
  SET_CURRENT_ERRORS(state, errors = {}) {
    state.currentErrors = errors;
  },
  REMOVE_CURRENT_ERROR(state, question) {
    const models = question.inputs.map((input) => input.model);

    models.forEach((model) => {
      delete state.currentErrors[model];
    });
  },
  SET_DOCUMENT_DATA(state, data) {
    let existingDocumentInstance = state.data.find(
      ({ type }) => type === data.type
    );

    if (existingDocumentInstance) existingDocumentInstance.data = data.data;
    else state.data.push(data);
  },
  SET_CURRENT_QUESTION_STATUS(state, data) {
    let statuses = state.currentQuestionStatus.filter(({ q }) => q !== data.q);
    statuses.push(data);

    state.currentQuestionStatus = statuses;
  },
  DELETE_CURRENT_QUESTION_STATUSES(state) {
    state.currentQuestionStatus = [];
  },
  DELETE_DOCUMENT_DATA(state, documentType) {
    state.data = state.data.filter(({ type }) => type !== documentType);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
