const state = {
  pageTitle: {},
  pageSubTitle: {},
};

const mutations = {
  SET_PAGE_TITLE(state, { routeFullPath, title }) {
    state.pageTitle = {
      ...state.pageTitle,
      [routeFullPath]: title,
    };
  },
  SET_PAGE_SUBTITLE(state, { routeFullPath, title }) {
    state.pageSubTitle = {
      ...state.pageSubTitle,
      [routeFullPath]: title,
    };
  },
};

export default {
  state,
  mutations,
};
