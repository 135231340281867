export default [
  {
    path: "cookie-consent",
    name: "cookie_consent_index",
    meta: {
      title: false,
      // sideCollapse: true
    },
    component: () =>
      import(
        /* webpackChunkName: "CookieConsentIndex" */ "../../views/Project/CookieConsent/Index.vue"
      ),
  },
  {
    path: "cookie-consent/new",
    name: "new_cookie_consent",
    meta: {
      title: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "NewCookieConsentBanner" */ "../../views/Project/CookieConsent/NewCookieConsentBanner.vue"
      ),
  },
  {
    path: "cookie-consent/:uuid",
    name: "cookie_consent",
    meta: {
      title: "Cookie Consent Banner",
      breadcrumbs: ["projects_index", "project_index"],
      pageContentHeaderComponent: () =>
        import(
          "../../components/Project/CookieConsent/CookieConsentBannerStatus.vue"
        ),
    },
    component: () =>
      import(
        /* webpackChunkName: "CookieConsentBannerView" */ "../../views/Project/CookieConsent/CookieConsentBannerView.vue"
      ),
  },
];
