import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import http from "./utils/service/http";
import "./utils/service/price";
import "./utils/service/log";
import "./scss/spa_main.scss";
import { eventLog } from "./global";
import vuetify from "./vuetify";
import globalVariables from "./utils/globalVariables";
import VueMeta from "vue-meta";
import i18n from "./utils/service/Translations/translations";
import { init as initAmplitude } from "./utils/amplitude";
import initGoogleAnalytics from "./utils/google-analytics";

// Toasted For Notifications
import Toasted from "vue-toasted";

Vue.use(Toasted, {
  position: "bottom-center",
  keepOnHover: true,
  iconPack: "mdi",
  className: "w-toasted",
  duration: 3000,
});

Vue.prototype.$axios = http;
Vue.prototype.$global = globalVariables;
Vue.prototype.$event = eventLog;
Vue.prototype.$utils = {
  locale: () => router.currentRoute.params.locale || "en",
};

Vue.use(VueMeta);

Vue.config.productionTip = false;

initAmplitude(process.env.VUE_APP_AMPLITUDE_API_KEY);
initGoogleAnalytics(process.env.VUE_APP_TAG_MANAGER_KEY);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
