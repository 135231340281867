import multiguard from "vue-router-multiguard";
import store from "../store";
import log from "../utils/service/log";
import $axios from "../utils/service/http";

export default [
  {
    path: "questionnaire/:business",
    meta: {
      layout: () => import("../layouts/OnboardingLayout"),
    },
    component: () =>
      import("../views/PublicQuestionnaire/PublicQuestionnaireWrapper.vue"),
    beforeEnter: multiguard([
      async (to, from, next) => {
        try {
          const { token } = await $axios.post("/api/v1/business/reg", {
            business: to.params.business,
          });
          await store.commit("SET_TOKEN", token);
          return next();
        } catch (e) {
          log.notify(e);
        }

        return next({
          ...to,
          name: "home",
        });
      },
    ]),
    children: [
      {
        path: "",
        redirect: (to) => {
          return {
            name: "public_questionnaire",
            params: {
              ...to.params,
              type: "company_info",
            },
          };
        },
      },
      {
        path: "thank-you",
        name: "public_questionnaire_thank_you",
        meta: {
          layout: () => import("../layouts/OnboardingLayout"),
        },
        component: () =>
          import(
            "../views/PublicQuestionnaire/PublicQuestionnaireThankYou.vue"
          ),
      },
      {
        path: "data_usage_purpose",
        meta: {
          title: false,
          layout: () => import("../layouts/Generation"),
          sideMenuComponent: () =>
            import("../components/Questionnaire/Sidebar/ProductSteps"),
        },
        component: () =>
          import(
            /* webpackChunkName: "QuestionnaireDataUsagePurpose" */ "../views/PublicQuestionnaire/PublicQuestionnaireDataUsagePurpose.vue"
          ),
        children: [
          {
            path: "",
            name: "public_questionnaire_data_usage_purpose",
            meta: {
              dupeRoute: "public_questionnaire_data_usage_purpose_edit",
              dupnRoute: "public_questionnaire_data_usage_purpose_new",
            },
            component: () =>
              import(
                /* webpackChunkName: "QuestionnaireDataUsagePurposeOverview" */ "../views/Project/Questionnaire/DataUsagePurposes/DataUsagePurposeOverview.vue"
              ),
          },
          {
            path: "new",
            name: "public_questionnaire_data_usage_purpose_new",
            component: () =>
              import(
                /* webpackChunkName: "QuestionnaireDataUsagePurposeNew" */ "../views/Project/Questionnaire/DataUsagePurposes/DataUsagePurpose"
              ),
          },
          {
            path: ":uuid",
            name: "public_questionnaire_data_usage_purpose_edit",
            component: () =>
              import(
                /* webpackChunkName: "QuestionnaireDataUsagePurposeEdit" */ "../views/Project/Questionnaire/DataUsagePurposes/DataUsagePurpose"
              ),
          },
        ],
      },
      {
        path: ":type",
        name: "public_questionnaire",
        meta: {
          layout: () => import("../layouts/Generation"),
          sideMenuComponent: () =>
            import("../components/Questionnaire/Sidebar/ProductSteps"),
        },
        component: () =>
          import("../views/PublicQuestionnaire/PublicQuestionnaire"),
        beforeEnter(to, from, next) {
          if (to.params.type === "thank-you") {
            return next({
              name: "public_questionnaire_thank_you",
              params: { locale: to.params.locale },
            });
          }

          if (to.params.type === "data_usage_purpose") {
            return next({
              name: "public_questionnaire_data_usage_purpose",
              params: { locale: to.params.locale },
            });
          }

          next();
        },
      },
    ],
  },
];
