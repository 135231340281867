import store from "../store/index";
import router from "../router";

export default (to, from, next) => {
  if (!store.state.auth.token) {
    router
      .push({ name: "login", params: router.currentRoute.params })
      .catch(() => {});
  }

  next();
};
